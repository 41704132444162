<template>
  <Layout>
    <div class="quienes-somos-restaurante">
      <div
        class="header-restaurante"
        :style="{
          'background-image':
            'url(' + require(`@/assets/images/restaurante-fondo.jpg`) + ')',
        }"
      >
        <div class="nombre-restaurante">{{ restaurante.nombre }}</div>
      </div>

      <div class="contenedor-restaurante">
        <div class="restaurante-descripcion">
          <h1 v-html="nosotros"></h1>

          <p>{{ restaurante.descripcion }}</p>

          <div class="restaurante-informacion">
            <div>
              <h3 id="telefono">{{traducciones.find((item)=>item.item == 'Telefono').value}}</h3>
              <p>{{ restaurante.telefono }}</p>
            </div>
            <div>
              <h3 id="horarios">{{traducciones.find((item)=>item.item == 'Horarios').value}}</h3>
              <span
                v-for="horario in restaurante.horario"
                :key="horario.id"
              >
              <p v-if="horario.desde !== null && horario.hasta !== null">{{semana[horario.dia-1]}} <label v-if="horario.desde !== null">{{ horario.desde.substr(0,5) }}</label>-<label v-if="horario.hasta !== null">{{ horario.hasta.substr(0,5) }}</label></p>
                <p v-if="horario.desde_partido !== null && horario.hasta_partido !== null">{{semana[horario.dia-1]}} <label v-if="horario.desde_partido !== null">{{ horario.desde_partido.substr(0,5) }}</label>-<label v-if="horario.hasta_partido !== null">{{ horario.hasta_partido.substr(0,5) }}</label> </p>
             
              </span>
            </div>

            <div>
              <h3 id="redes">{{traducciones.find((item)=>item.item == 'Redes sociales').value}}</h3>

              <ul>
                <li>
                  <a href="#">
                    <img src="@/assets/images/facebook.svg" alt="" />
                  </a>
                </li>

                <li>
                  <a href="#">
                    <img src="@/assets/images/whatsapp.svg" alt="" />
                  </a>
                </li>

                <li>
                  <a href="#">
                    <img src="@/assets/images/instagram.svg" alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

import Layout from "@/layout/Layout.vue";

export default {
  name: "Quienes somos",
  components: {
    Layout,
  },
  methods:
        {
        getDayOfWeek(dow) {
            const date = new Date();
            const day = date.getDay();
            const diff = date.getDate() - day;

            var dia = new Date(date.setDate(diff+1));
            for (var i=1;i<dow;i++){
                dia.setDate(dia.getDate() + 1);
            }
            return dia;    
        }
        
        },
     created: function(){
        for (var i=1;i<8;i++)
            this.semana.push(this.getDayOfWeek(i).toLocaleDateString(undefined, { weekday: 'long'}).substr(0,3));
            
     },
  setup() {
    const store = useStore();
    const restaurante = computed(() => store.state.restaurante.restaurante);
    console.log(restaurante.horario);  
    var nosotros = store.state.restaurante.restaurante.idiomas.filter( item => item.id === store.state.idiomas.options.locale).map(item => {return item.nosotros});
     var semana = [];
const traducciones = store.state.restaurante.restaurante.traducciones.filter( item => item.locale === store.state.idiomas.options.locale)
    return {
      store,
      restaurante,
      nosotros,
      semana,
      traducciones
    };
  },
};
</script>

<style lang="scss" scoped>
.quienes-somos-restaurante {
  .header-restaurante {
    padding: 25vh 0;
    background: rgb(245, 244, 244);
    background-image: url("/assets/img/restaurante-fondo.jpg");
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 698px) {
      padding: 150px 0;
    }
  }

  .nombre-restaurante {
    text-align: center;
    font-size: 42px;
    font-weight: bold;
    color: #fff;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    text-shadow: 2px 2px 0px #000;
    padding: 10px;

    @media (max-width: 698px) {
      font-size: 15px;
    }
  }

  .contenedor-restaurante {
    width: 1024px;
    margin: 0 auto;
    padding: 50px;
    transform: translateY(-100px);
    background: #fff;
    box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.0844897);

    @media (max-width: 1024px) {
      width: 90%;
      padding: 25px;
    }
  }

  .restaurante-informacion {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    @media (max-width: 698px) {
      grid-template-columns: 1fr;
    }

    p {
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0;
      display: flex;
    }

    li {
      margin-right: 15px;
      list-style: none;

      &:last-child {
        margin-right: 0;
      }
    }

    img {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
